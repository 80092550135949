<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">囤货订单详情</div>

			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">

					<div class="">
						<div class="flex alcenter">
							<div class="ml20">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.store_order_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">订单类型：</div>
										<div class="ft14 ftw600 cl-theme text-over4">囤货订单</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="mall-store-goods-order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==1">待付款</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==4">待到店自提</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==8">已完成</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==-1">已取消</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter">
															<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
															<div class="ft14 ftw600 cl-main text-over4">¥{{order.need_pay}}</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
															<div class="ft14 ftw600 cl-main text-over4" >{{order.pay_type_mean}}</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
															<div class="ft14 ftw600 cl-main text-over4" >{{order.pay_time_format}}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="ml40">
										<div class="ft14 ftw500 cl-main">客户信息</div>
										<div class="mt16">
											<div class="flex">
												<div>
													<div class="flex alcenter">
														<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
														<div class="ft14 ftw600 cl-main text-over4">{{order.member_name}}</div>
													</div>
													<div class="flex alcenter mt10">
														<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
														<div class="ft14 ftw600 cl-main text-over4">{{order.member_mobile}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="flex wrap">
							<div class="mall-store-goods-order-detail-subcard-item">
								<div class="flex  alcenter space">
									<div class="flex alcenter">
										<img v-if="order.goods_cover_img != null " :src="order.goods_cover_img" />
										<div class="ml10">
											<div class="ft14 ftw500 cl-info" style="width: 60%;">
												{{order.goods_name}}
											</div>				
										</div>
										<div style="margin-left: 200px;" class="ft14 ftw500 cl-info flex alcenter">
											<div>数量</div>
											<div class="ml8">{{order.goods_num}}</div>
										</div>
									</div>

									<div v-if="order.status==4 " class="flex alcenter">
										<div class="ft14 ftw500 cl-info">剩余： {{order.remain_num}}</div>
										<div class="button-deliver ml20" @click="writeOffAct()">囤货核销</div>
									</div>

								</div>
							</div>
						</div>
					</div>

					<div class="flex end mt10">
						<div class="flex">
							<div class="ft12 ftw400 cl-info">
								<div class="flex end">合计</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">优惠金额</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
							</div>

							<div class="ml60 ft12 ftw400 cl-info">
								<div class="flex end">¥{{order.goods_total_price}}</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">¥{{order.youhui_balance}}</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">¥{{order.need_pay}}</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="mall-store-goods-order-detail-give-item">
							<div class="flex alcenter">
								<div class="ft16 ftw500 cl-main">赠送礼品</div>
								<div class="ml20">
									<div class="flex alcenter">
										<div class="ft12 ftw400 cl-notice">总价值：</div>
										<div class="ft12 ftwblod cl-theme">¥{{order.gift_total_price}}</div>
									</div>
								</div>
							</div>

							<div class="">
								<div class="flex wrap">
									<div class="mt16" style="width: 100%;" v-for="(gift,index) in order.goods_gift_giving">
										<div class="flex  alcenter space" style="width: 100%;">
											<div class="flex alcenter" style="width: 100%;">
												<div class="ft14 ftw500 cl-info" style="width: 40%;">
													{{gift.name}}
												</div>
												<div style="width:60% ; "
													class="ft14 ftw500 cl-info flex alcenter">
													<div>¥{{gift.price}}</div>
													<div class="ml8">x{{gift.num}}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="writeOffVisible">
				<write-off :order="order" :visible="writeOffVisible" @cancel="cancelWriteOff" @ok="okWriteOff"></write-off>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import writeOff from './components/StoreOrderDetail/modal/writeOff.vue';
	export default {
		components: {
			writeOff,
		},
		data() {
			return {
				loading: false,
				writeOffVisible: false,
				store_order_id: 0,
				order: {
					store_order_id: 0,
					add_time_format: '',
					status: 1,
					status_means: '',
					need_pay: 0,
					pay_type: '',
					pay_type_mean: '',
					pay_time_format: '',
					goods_total_price: 0,
					youhui_balance: 0,
					member_name: '',
					member_mobile: '',
					goods_name: '',
					goods_cover_img: '',
					goods_num: 0,
					remain_num: 0,
					goods_gift_giving: [],
					gift_total_price:0,
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.store_order_id = 0;
			} else {
				this.store_order_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods: {

			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getStoreOrderDetail', {
					store_order_id: this.store_order_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},

			writeOffAct() {
				this.writeOffVisible = true;
			},
			cancelWriteOff() {
				this.writeOffVisible = false;
			},
			okWriteOff() {
				this.writeOffVisible = false;
				this.loaddata();
			}
		}
	}
</script>

<style>
	.mall-store-goods-order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.mall-store-goods-order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.mall-store-goods-order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.mall-store-goods-order-detail-subcard-item {
		width: 100%;
		height: 100px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.mall-store-goods-order-detail-subcard-item img {
		width: 75px;
		height: 60px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.mall-store-goods-order-detail-give-item {
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		padding: 20px;
	}
</style>
