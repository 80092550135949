<template>
	<div>
		<a-modal title="囤货商品核销" :width="540" :visible="visible" :confirm-loading="confirmLoading" @cancel="handleCancel" >
			<template slot="footer">
			        <a-button key="back" @click="handleCancel">
			          取消
			        </a-button>
			        <a-button key="submit" type="primary"  @click="onSubmit"  :loading="confirmLoading">
			          确认核销
			        </a-button>
			      </template>
			<a-spin :spinning="loading">
				<div class="member-write-off-content">
					<div class="ft14 ftw600 cl-black flex center">{{order.name}} </div>
					<div class="flex space mt20 center">
						<i v-if="form.num<=0" class="iconfont iconminus_small cl-info mr40 ft40"/>
						<i v-else class="iconfont iconminus_small cl-theme mr40 clickAct ft40" @click="deductionNum()"/>
						
						<div class="flex alcenter center ft24 ftw600 cl-main">{{form.num}}</div>
						
						<i  class="iconfont iconadd_small1 cl-theme ml40 clickAct ft40" v-if="getRemainNum>0" @click="plusNum()"/>
						<i  class="iconfont iconadd_small1 cl-info ml40 clickAct ft40"v-else/>
					</div>
					
					<div class="flex space mt20">
						<div class="flex alcenter ">
							<div class="ft12 ftw400 cl-notice">总次数</div>
							<div class="ft14 ftw600 cl-black ml5">{{order.goods_num}}次</div>
						</div>
						
						<div class="flex alcenter ">
							<div class="ft12 ftw400 cl-notice">剩余次数</div>
							<div class="ft14 ftw600 cl-theme ml5">{{getRemainNum}}次</div>
						</div>
					</div>
				</div>
				
				<div class="flex center mt24">
					<div class="detail-modal-integral-deduction-info">
						<a-input style="width: 420px;" placeholder="如非会员本人消费，请备注好关系姓名" v-model="form.remarks" :maxLength="20">
							<div slot="prefix" class="input-addon-before">
								备注
							</div>
						</a-input>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			order:{
				type:Object,
				default:function(){
					return new Object
				}
			},
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				form:{
					num:0,
					remarks:'',
				}
			}
		},
		computed:{
			getRemainNum(){
				let remain=parseInt(this.order.remain_num) - parseInt(this.form.num);
				return remain >0 ? remain : 0;
			}
		},
		methods:{
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
				
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/writeOffStoreOrder',{
					store_order_id:this.order.store_order_id,
					num:this.form.num,
					remarks:this.form.remarks,
				}).then(res=>{
					this.$message.success('核销成功',1,()=>{
						this.$emit("ok");
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			/**
			 * 减数量
			 */
			deductionNum(){
				if(this.form.num==0){
					this.$message.error('已减至0了');
					return;
				}
				this.form.num=this.form.num-1;
			},
			
			/**
			 * 加数量
			 */
			plusNum(){
				if(this.getRemainNum<=0){
					this.$message.error('以达到核销上限了');
					return;
				}
				this.form.num=this.form.num+1;
			},
		}
	}
</script>

<style>
	.member-write-off-content{
		padding: 20px 100px 20px 100px;
	}
	
	.member-write-off-content-deduction{
		width: 50px;
		height: 50px;
		border-radius: 30px;
		border: 2px solid #5E40FF;
	}
	
	.member-write-off-content-deduction-disable{
		width: 50px;
		height: 50px;
		border-radius: 30px;
		border: 2px solid #999999;
	}
	
	.member-write-off-content-deduction-logo{
		width: 24px;
		height: 4px;
		background: #5E40FF;
		border-radius: 2px;
	}
	
	.member-write-off-content-deduction-logo-disable{
		width: 24px;
		height: 4px;
		background: #999999;
		border-radius: 2px;
	}
	
	.member-write-off-content-plus{
		width: 50px;
		height: 50px;
		background: #5E40FF;
		border-radius: 30px;
	}
	
	.member-write-off-content-plus-disable{
		width: 50px;
		height: 50px;
		background: #999999;
		border-radius: 30px;
	}
	
	.member-write-off-content-plus-logo{
		font-size: 25px;
		color:#ffffff;
		
	}
	
	.detail-modal-integral-deduction-info .ant-input-affix-wrapper .ant-input-prefix, .ant-input-affix-wrapper .ant-input-suffix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 35px;
	}
	
	.detail-modal-integral-deduction-info .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 59px;
	}
</style>
